.section-requirements {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 70.6px;
  padding-bottom: 90px;

  background-color: $requirements;
  background-position: center top;
  background-repeat: no-repeat;
  background-image: url(../assets/misc/separator-two.png);
  background-image:
    -webkit-image-set(
      url(../assets/misc/separator-two.png) 1x,
      url(../assets/misc/separator-two@2x.png) 2x,
    );
  background-image: 
    image-set(
      url(../assets/misc/separator-two.png) 1x,
      url(../assets/misc/separator-two@2x.png) 2x,
    );

  .title {
    font-family: Axia;
    font-size: 18px;
    font-weight: 900;
    line-height: 2.83;
    letter-spacing: 0.72px;
    color: #d7e0f0;
    text-transform: uppercase;

    margin-top: 59.3px;
    margin-bottom: 35px;

    @include desktop {
      font-size: 22px;
      line-height: 1.64;
      letter-spacing: 0.88px;

      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  p {
    font-family: Axia;
    font-size: 16px;
    line-height: 1.44;
    letter-spacing: 0.32px;
    color: #b3c1e0;

    strong {
      color: #b3c1e0;
    }

    @include desktop {
      font-size: 18px;
      line-height: 1.39;
      letter-spacing: 0.72px;
    }
  }

  figure {
    margin-left: 30px;
    margin-right: 30px;

    @include desktop {
      margin-left: 218px;
      margin-right: 218px;
    }

    @include widescreen {
      margin-left: 387px;
      margin-right: 387px;
    }

    @include widescreen-hd {
      margin-left: 403px;
      margin-right: 403px;
    }

    @include fullhd {
      margin-left: 484px;
      margin-right: 484px;
    }

    @include fullhd-xl {
      margin-left: 564px;
      margin-right: 564px;
    }
  }


  @include desktop {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 80.1px;
    padding-bottom: 86.4px;
  }
}