.header {
  position: relative;

  .hero-body {
    padding: 0;
    background: none;
    #hero-logo {
      max-width: 800px;
      width: 90%;
    }
  }

  .hero-background {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);
      z-index: 1;
  
      @include desktop {
        display: none;
      }
    }

    .video-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      #background-image {
        object-fit: cover !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    video {
      object-fit: cover;
      height: 120%;
      width: 120%;

      display: none;

      @include desktop {
        display: block;
        height: 100%;
      }
    }

    video[poster] {
      object-fit: cover;
    }
  }

  @include tablet {
    background-position: center;
  }

  .hero-body {
    z-index: 2;
    @include desktop {
      padding-bottom: 0px;
    }
  }

  &:after {
    z-index: 1000;
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, rgba(254, 87, 36, 0.5), #fe5724 50%, rgba(254, 87, 36, 0.5));
  }

  figure {
    margin-top: 61px;
    margin-left: 11px;
    margin-right: 11px;

    @include desktop {
      margin-top: 60px;
      margin-left: 145px;
      margin-right: 145px;
    }

    @include widescreen {
      margin-top: 88px;
      margin-left: 270px;
      margin-right: 270px;
    }

    @include widescreen-hd {
      margin-top: 95px;
      margin-left: 296px;
      margin-right: 296px;
    }

    @include fullhd {
      margin-top: 117px;
      margin-left: 332px;
      margin-right: 332px;
    }

    @include fullhd-xl {
      margin-top: 124px;
      margin-left: 430px;
      margin-right: 430px;
    }
  }

  .title {
    margin-top: 222px;

    color: $white;
    text-transform: uppercase;
    line-height: 1.21;
    letter-spacing: 0.68px;
    font-size: 34px;
    font-weight: 900;
    text-shadow: 0 0 20px #000000, 0 0 20px #000000;

    @include tablet {
      margin-top: 160px;
    }

    @include desktop {
      margin-top: 144px;
      font-size: 48px;
      line-height: 1.21;
      letter-spacing: 1.26px;
      color: $white;
    }

    @include widescreen {
      margin-top: 177px;
      font-size: 48px;
      line-height: 1.22;
      letter-spacing: 1.3px;      
    }

    @include widescreen-hd {
      margin-top: 140px;
      font-size: 48px;
      line-height: 1.2;
      letter-spacing: 1.52px;    
    }

    @include fullhd {
      margin-top: 183px;
    }

    @include fullhd-xl {
      margin-top: 145px;
      font-size: 48px;
      line-height: 1.2;
      letter-spacing: 1.68px;
    }
  }

  .subtitle {
    color: $light;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    text-shadow: 0 0 20px #000000, 0 0 20px #000000;

    margin-left: 90px;
    margin-right: 90px;

    @include desktop {
      font-size: 36px;
      line-height: 1.2;
      letter-spacing: 0.8px;
      color: $white;

      margin-left: 0px;
      margin-right: 0px;
    }

    @include widescreen {
      font-size: 36px;
      font-weight: bold;
      line-height: 1.21;
      letter-spacing: 0.78px;
    }

    @include widescreen-hd {
      font-size: 36px;
      line-height: 1.2;
      letter-spacing: 0.92px;
    }

    @include fullhd-xl {
      font-size: 34px;
      line-height: 1.2;
      letter-spacing: 1.08px;      
    }
  }

  .button {
    margin-top: 20px;
    margin-bottom: 46px;

    @include desktop {
      margin-top: 20px;
      margin-bottom: 60px;
    }

    @include widescreen {
      margin-top: 20px;
      margin-bottom: 90px;
    }

    @include widescreen-hd {
      margin-top: 20px;
      margin-bottom: 100px;
    }

    @include fullhd {
      margin-top: 20px;
      margin-bottom: 105px;
    }

    @include fullhd-xl {
      margin-top: 20px;
      margin-bottom: 114px;
    }
  }
}