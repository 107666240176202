.section-videos {
  .title {
    margin-top: 51.7px;

    @include fullhd {
      margin-top: 80px;
    }

    @include fullhd-xl {
      margin-top: 96px;
      margin-bottom: 30px;
    }
  }

  .subtitle {
    color: $lighter;
    margin-bottom: 43px;
  }

  .video-entry {
    position: relative;
    margin-bottom: 6px;
    border: 1px solid $blue-darker;
    overflow: hidden;

    .video-entry-background {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;

      img {
        width: 100%;
      }
    }

    h3 {
      text-shadow: 0 0 10px $black;
      color: $white;
      font-family: Axia;
      font-weight: 900;
      font-size: 22px;
      line-height: 2.32;
      letter-spacing: 0.44px;
      text-transform: uppercase;
      position: absolute;
      left: 16px;
      bottom: 8px;
      z-index: 1;

      @include desktop {
        font-size: 18px;
        line-height: 1.61;
        letter-spacing: 0.36px;

        left: 11px;
        bottom: 11px;
      }

      @include widescreen {
        font-size: 23px;
        line-height: 1.61;
        letter-spacing: 0.46px;        

        left: 13px;
        bottom: 13px;
      }

      @include fullhd {
        font-size: 24px;
        line-height: 1.63;
        letter-spacing: 0.48px;

        left: 14px;
        bottom: 10px;
      }

      @include fullhd-xl {
        font-size: 31px;
        line-height: 1.65;
        letter-spacing: 0.62px;

        left: 16px;
        bottom: 11px;
      }
    }

    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    .play-overlay {
      content: '';
      position: absolute;
      display: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
  
      cursor: pointer;
  
      background-size: 100px;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(../assets/buttons/button-play.png);
    }
  }

  @include desktop {
    margin-left: 25px;
    margin-right: 25px;
  }

  @include widescreen {
    margin-left: 83px;
    margin-right: 83px;
  }

  @include widescreen-hd {
    margin-left: 120px;
    margin-right: 120px;
  }

  @include fullhd {
    margin-left: 160px;
    margin-right: 160px;
  }

  @include fullhd-xl {
    margin-left: 200px;
    margin-right: 200px;
  }
}

.section-top-playstyle {
  pointer-events: none;

  @include widescreen {
    width: 100%;
    height: 433px;

    position: absolute;
    z-index: 1000;
    margin-top: -55px;
    
    background-position: -3px top, calc(100% + 3px) top;
    background-repeat: no-repeat;
    background-image: url(../assets/misc/hex-mid-left.png), url(../assets/misc/hex-mid-right.png);
    background-image:
      -webkit-image-set(
        url(../assets/misc/hex-mid-left.png) 1x,
        url(../assets/misc/hex-mid-left@2x.png) 2x,
      ),
      -webkit-image-set(
        url(../assets/misc/hex-mid-right.png) 1x,
        url(../assets/misc/hex-mid-right@2x.png) 2x,
      );
    background-image: 
      image-set(
        url(../assets/misc/hex-mid-left.png) 1x,
        url(../assets/misc/hex-mid-left@2x.png) 2x,
      ),
      image-set(
        url(../assets/misc/hex-mid-right.png) 1x,
        url(../assets/misc/hex-mid-right@2x.png) 2x,
      );
  }

  @include fullhd {
    margin-top: -35px;
  }

  @include fullhd-xl {
    background-position: 0px top, 100% top;
    background-image: url(../assets/misc/hex-mid-left-hd.png), url(../assets/misc/hex-mid-right-hd.png);
    background-image:
      -webkit-image-set(
        url(../assets/misc/hex-mid-left-hd.png) 1x,
        url(../assets/misc/hex-mid-left-hd@2x.png) 2x,
      ),
      -webkit-image-set(
        url(../assets/misc/hex-mid-right-hd.png) 1x,
        url(../assets/misc/hex-mid-right-hd@2x.png) 2x,
      );
    background-image: 
      image-set(
        url(../assets/misc/hex-mid-left-hd.png) 1x,
        url(../assets/misc/hex-mid-left-hd@2x.png) 2x,
      ),
      image-set(
        url(../assets/misc/hex-mid-right-hd.png) 1x,
        url(../assets/misc/hex-mid-right-hd@2x.png) 2x,
      );
  }
}