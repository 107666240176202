.section-players {
  .section-image {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%);
    }
  }

  .section-content {
    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-image: linear-gradient(to right, rgba(104, 57, 183, 0.5), #6839b7 50%, rgba(104, 57, 183, 0.5));  
    }

    .title {
      @include desktop {
        text-align: right;

        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-right: 48px;
        width: 100%;
        padding-left: 55%;
      }

      @include widescreen {
        padding-left: 56%;
      }

      @include fullhd {
        padding-left: 58%;
      }

      @include fullhd-xl {
        padding-left: 56%;
      }
    }

    .subtitle {
      @include desktop {
        text-align: right;

        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-right: 48px;
        width: 100%;
        padding-left: 50%;
      }

      @include fullhd {
        padding-left: 56%;
      }

      @include fullhd-xl {
        padding-left: 54%;
      }
    }
  }
}