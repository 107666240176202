.section-frontier {
  background-color: black;

  .title {
    text-transform: uppercase;
    font-size: 38px;
    font-weight: 900;
    line-height: 1.24;
    letter-spacing: 0.76px;
    color: $white; 

    margin-top: 30px;
    margin-bottom: 13px;
    margin-left: 44px;
    margin-right: 44px;

    @include desktop {
      font-size: 55px;
      line-height: 0.6;
      letter-spacing: 1.1px;

      margin-top: -120px;
      margin-bottom: 80px;
    }

    @include widescreen {
      font-size: 61px;
      line-height: 0.54;
      letter-spacing: 1.22px;      
    }
  }

  .button {
    margin-top: 10px;
    margin-bottom: 46px;

    @include desktop {
      margin-top: -30px;
      margin-bottom: 60px;
    }

    @include widescreen {
      margin-top: -20px;
      margin-bottom: 60px;
    }

    @include widescreen-hd {
      margin-top: -20px;
      margin-bottom: 70px;
    }

    @include fullhd {
      margin-top: -20px;
      margin-bottom: 75px;
    }

    @include fullhd-xl {
      margin-top: -30px;
      margin-bottom: 75px;
    }
  }

  @include desktop {
    padding-left: 25px;
    padding-right: 25px;
  }
}
