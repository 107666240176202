.section-forge {
  @include desktop {
    margin-bottom: 50px;
  }

  @include widescreen {
    margin-bottom: 100px;
  }

  @include widescreen-hd {
    margin-bottom: 85px;
  }

  @include fullhd {
    margin-bottom: 87px;
  }

  @include fullhd-xl {
    margin-bottom: 61px;
  }
}
