.section-war {
  .section-image {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%);
    }
  }
  
  .section-content {
    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-image: linear-gradient(to right, rgba(18, 168, 243, 0.5), #12a8f3 50%, rgba(18, 168, 243, 0.5));
    }
  }
}
