@font-face {
  font-family: 'Axia';
  src: url('./fonts/Axia-Italic.woff2') format('woff2'),
      url('./fonts/Axia-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Axia';
  src: url('./fonts/AxiaLight-Italic.woff2') format('woff2'),
      url('./fonts/AxiaLight-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Axia';
  src: url('./fonts/Axia-Regular.woff2') format('woff2'),
      url('./fonts/Axia-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Axia';
  src: url('./fonts/AxiaLight-Regular.woff2') format('woff2'),
      url('./fonts/AxiaLight-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Axia';
  src: url('./fonts/Axia-BoldItalic.woff2') format('woff2'),
      url('./fonts/Axia-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Axia';
  src: url('./fonts/Axia-Bold.woff2') format('woff2'),
      url('./fonts/Axia-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Axia';
  src: url('./fonts/AxiaBlack-Regular.woff2') format('woff2'),
      url('./fonts/AxiaBlack-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Axia Stencil';
  src: url('./fonts/AxiaStencilLight-Regular.woff2') format('woff2'),
      url('./fonts/AxiaStencilLight-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Axia Stencil';
  src: url('./fonts/AxiaStencilBlack-Regular.woff2') format('woff2'),
      url('./fonts/AxiaStencilBlack-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Axia';
  src: url('./fonts/AxiaBlack-Italic.woff2') format('woff2'),
      url('./fonts/AxiaBlack-Italic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

