@charset "utf-8";

// Set your brand colors
$white: #ffffff;
$light: #d7e0f0;
$lighter: #b3c1e0;
$purple: #8a4d76;
$pink: #fa7c91;
$red: #B0423F;
$brown: #0A060F;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$blue: rgb(0, 153, 255);
$blue-lighter: rgba(0, 153, 255, 0.5);
$blue-darker: #37469A;
$dark: rgb(9, 5, 15);
$darker: #191F25;
$requirements: #0C0D14;
$gallery: #05090F;

// Update Bulma's global variables
$family-primary: 'Axia', serif;
$family-sans-serif: 'Axia', sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$widescreen-enabled: true;
$fullhd-enabled: true;

// Update some of Bulma's component variables
$body-background-color: #000000;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// Breakpoints
$widescreen: 1336px;
$fullhd: 1600px;

// Import only what you need from Bulma
@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/elements/button.sass';
@import '../../node_modules/bulma/sass/elements/container.sass';
@import '../../node_modules/bulma/sass/form/all.sass';
@import '../../node_modules/bulma/sass/elements/title.sass';
@import '../../node_modules/bulma/sass/elements/notification.sass';
@import '../../node_modules/bulma/sass/elements/other.sass';
@import '../../node_modules/bulma/sass/components/navbar.sass';
@import '../../node_modules/bulma/sass/components/message.sass';
@import '../../node_modules/bulma/sass/layout/hero.sass';
@import '../../node_modules/bulma/sass/layout/section.sass';
@import '../../node_modules/bulma/sass/grid/columns.sass';

@import "fonts";
@import "mixins";

.section-main {
  .section-top {
    pointer-events: none;

    @include desktop {
      width: 100%;
      height: 351px;

      position: absolute;
      z-index: 1000;
      margin-top: -140px;
      
      background-position: -33px top, calc(100% + 33px) top;
      background-repeat: no-repeat;
      background-image: url(../assets/misc/hex-left.png), url(../assets/misc/hex-right.png);
      background-image:
        -webkit-image-set(
          url(../assets/misc/hex-left.png) 1x,
          url(../assets/misc/hex-left@2x.png) 2x,
        ),
        -webkit-image-set(
          url(../assets/misc/hex-right.png) 1x,
          url(../assets/misc/hex-right@2x.png) 2x,
        );
      background-image: 
        image-set(
          url(../assets/misc/hex-left.png) 1x,
          url(../assets/misc/hex-left@2x.png) 2x,
        ),
        image-set(
          url(../assets/misc/hex-right.png) 1x,
          url(../assets/misc/hex-right@2x.png) 2x,
        );
    }

    @include widescreen {
      background-position: -3px top, calc(100% + 3px) top;
    }

    @include fullhd-xl {
      height: 479px;
      background-position: 0px top, 100% top;
      background-image: url(../assets/misc/hex-left-hd.png), url(../assets/misc/hex-right-hd.png);
      background-image:
        -webkit-image-set(
          url(../assets/misc/hex-left-hd.png) 1x,
          url(../assets/misc/hex-left-hd@2x.png) 2x,
        ),
        -webkit-image-set(
          url(../assets/misc/hex-right-hd.png) 1x,
          url(../assets/misc/hex-right-hd@2x.png) 2x,
        );
      background-image: 
        image-set(
          url(../assets/misc/hex-left-hd.png) 1x,
          url(../assets/misc/hex-left-hd@2x.png) 2x,
        ),
        image-set(
          url(../assets/misc/hex-right-hd.png) 1x,
          url(../assets/misc/hex-right-hd@2x.png) 2x,
        );
    }
  }

  @include desktop {
    background-size: cover;
    background-position: center 50px;
    background-image: url(../assets/bg/bg-desktop.jpg);
    background-image: 
      -webkit-image-set(
        url(../assets/bg/bg-desktop.jpg) 1x,
        url(../assets/bg/bg-desktop@2x.jpg) 2x,
      );
    background-image: 
      image-set(
        url(../assets/bg/bg-desktop.jpg) 1x,
        url(../assets/bg/bg-desktop@2x.jpg) 2x,
      );
  }

  @include widescreen {
    background-position: center top;
    background-image: url(../assets/bg/bg-widescreen.jpg);
    background-image: 
      -webkit-image-set(
        url(../assets/bg/bg-widescreen.jpg) 1x,
        url(../assets/bg/bg-widescreen@2x.jpg) 2x,
      );
    background-image: 
      image-set(
        url(../assets/bg/bg-widescreen.jpg) 1x,
        url(../assets/bg/bg-widescreen@2x.jpg) 2x,
      );
  }

  @include widescreen-hd {
    background-position: center top;
    background-image: url(../assets/bg/bg-widescreen-hd.jpg);
    background-image: 
      -webkit-image-set(
        url(../assets/bg/bg-widescreen-hd.jpg) 1x,
        url(../assets/bg/bg-widescreen-hd@2x.jpg) 2x,
      );
    background-image: 
      image-set(
        url(../assets/bg/bg-widescreen-hd.jpg) 1x,
        url(../assets/bg/bg-widescreen-hd@2x.jpg) 2x,
      );
  }

  @include fullhd {
    background-image: url(../assets/bg/bg-fullhd.jpg);
    background-image: 
      -webkit-image-set(
        url(../assets/bg/bg-fullhd.jpg) 1x,
        url(../assets/bg/bg-fullhd@2x.jpg) 2x,
      );
    background-image: 
      image-set(
        url(../assets/bg/bg-fullhd.jpg) 1x,
        url(../assets/bg/bg-fullhd@2x.jpg) 2x,
      );
  }

  @include fullhd-xl {
    background-image: url(../assets/bg/bg-fullhd-xl.jpg);
    background-image: 
      -webkit-image-set(
        url(../assets/bg/bg-fullhd-xl.jpg) 1x,
        url(../assets/bg/bg-fullhd-xl@2x.jpg) 2x,
      );
    background-image: 
      image-set(
        url(../assets/bg/bg-fullhd-xl.jpg) 1x,
        url(../assets/bg/bg-fullhd-xl@2x.jpg) 2x,
      );
  }
}

.section-text, .section-video, .section-videos {
  .title {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 900;
    line-height: 1.04;
    letter-spacing: 0.52px;
    color: $white;
    text-shadow: 0 0 20px #000000;
    
    margin-top: 21px;
    margin-bottom: 13px;

    @include desktop {
      font-size: 33px;
      line-height: 0.94;
      letter-spacing: 0.66px;

      margin-top: 67px;
      margin-bottom: 17px;
    }

    @include widescreen {
      font-size: 42px;
      line-height: 0.95;
      letter-spacing: 0.84px;
    }

    @include fullhd {
      font-size: 44px;
      line-height: 0.95;
      letter-spacing: 0.88px;
    }

    @include fullhd-xl {
      font-size: 70px;
      line-height: 0.73;
      letter-spacing: 1.4px;
    }
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 0.16px;
    color: $light;
    margin: 0px 19px 19px 19px;
    text-shadow: 0 0 10px #000000;

    @include desktop {
      font-size: 22px;
      line-height: 1.41;
      letter-spacing: 0.22px;

      margin-left: 207px;
      margin-right: 207px;
      margin-bottom: 27px;
    }

    @include widescreen {
      font-size: 22px;
      line-height: 1.41;
      letter-spacing: 0.22px;
    }

    @include fullhd {
      font-size: 22px;
      line-height: 1.41;
      letter-spacing: 0.22px;
    }

    @include fullhd-xl {
      font-size: 24px;
      line-height: 1.38;
      letter-spacing: 0.24px;
    }
  }
}

.section-text {
  position: relative;
  overflow: hidden;

  .section-image {
    position: relative;

    @include desktop {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      height: 487px;
    }

    @include widescreen {
      height: 620px;
    }

    @include widescreen-hd {
      height: 641px;
    }

    @include fullhd {
      height: 697px;
    }

    @include fullhd-xl {
      height: 800px;
    }

    picture {
      display: block;

      img {
        width: 100%;
      }
    }
  }

  .section-content {
    @include desktop {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }

  .title {
    @include desktop {
      font-size: 30px;
      line-height: 1.1;
      letter-spacing: 0.6px;
      font-weight: 900;

      text-shadow: 0 0 20px #000000;

      text-align: left;
      margin-left: 48px !important;
      margin-right: 0 !important;
      width: 39%;
    }

    @include widescreen {
      font-size: 36px;
      line-height: 1.11;
      letter-spacing: 0.72px;    

      margin-left: 60px !important;

      width: 46%;
    }

    @include widescreen-hd {
      font-size: 38px;
      line-height: 1.11;
      letter-spacing: 0.76px;      

      margin-left: 62px !important;
    }

    @include fullhd {
      font-size: 40px;
      line-height: 1.1;
      letter-spacing: 0.8px;

      margin-left: 71px !important;
    }

    @include fullhd-xl {
      font-size: 50px;
      line-height: 1.02;
      letter-spacing: 1px;

      margin-left: 81px !important;
    }
  } 

  .subtitle {
    @include desktop {
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: 0.2px; 

      text-shadow: 0 0 10px #000000;

      color: $white;

      text-align: left;
      margin-left: 48px !important;
      margin-right: 0 !important;
      width: 39%;

      margin-bottom: 38px;
    }

    @include widescreen {
      font-size: 22px;
      line-height: 1.36;
      letter-spacing: 0.22px;

      margin-left: 60px !important;
      margin-bottom: 60px;

      width: 46%;
    }

    @include widescreen-hd {
      font-size: 22px;
      line-height: 1.36;
      letter-spacing: 0.22px;

      margin-left: 62px !important;
      margin-bottom: 62px;
    }

    @include fullhd {
      font-size: 22px;
      line-height: 1.18;
      letter-spacing: 0.22px;      

      margin-left: 71px !important;
      margin-bottom: 71px;
    }

    @include fullhd-xl {
      font-size: 27px;
      line-height: 1.19;
      letter-spacing: 0.27px;      

      margin-left: 81px !important;
      margin-bottom: 81px;
    }
  } 

  @include desktop {
    background-size: cover;
    background-position: center center;
  
    height: 487px;
    margin-left: 25px;
    margin-right: 25px;
  }

  @include widescreen {
    height: 620px;
    margin-left: 63px;
    margin-right: 63px;
  }

  @include widescreen-hd {
    height: 641px;
    margin-left: 104px;
    margin-right: 104px;
  }

  @include fullhd {
    height: 697px;
    margin-left: 120px;
    margin-right: 120px;
  }

  @include fullhd-xl {
    height: 800px;
    margin-left: 160px;
    margin-right: 160px;
  }
}

.section-video, .section-videos {
  padding-left: 10px;
  padding-right: 10px;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 60px #F5403E;
  }
  70% {
    -webkit-box-shadow: 0 0 40px #F5403E;
  }
  100% {
    -webkit-box-shadow: 0 0 60px #F5403E;
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 60px #F5403E;
    box-shadow: 0 0 60px #F5403E;
  }
  70% {
    -moz-box-shadow: 0 0 40px #F5403E;
    box-shadow: 0 0 40px #F5403E;
  }
  100% {
    -moz-box-shadow: 0 0 60px #F5403E;
    box-shadow: 0 0 60px #F5403E;
  }
}

.button {
  color: $white;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.21;
  letter-spacing: 0.87px;

  width: 282px;
  height: 64px;

  border: 0;
  background-color: transparent;
  padding: 0;

  background-image: url(../assets/buttons/button.png);
  background-image: 
    -webkit-image-set(
      url(../assets/buttons/button-mobile.png) 1x,
      url(../assets/buttons/button-mobile@2x.png) 2x,
    );
  background-image: 
    image-set(
      url(../assets/buttons/button-mobile.png) 1x,
      url(../assets/buttons/button-mobile@2x.png) 2x,
    );

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
    right: 10px;

    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 0 60px #F5403E;
    animation: pulse 5s infinite;
  }

  &:active, &:visited, &:hover {
    color: $white;
  }

  &:hover {
    &:before {
      animation: none;
      box-shadow: 0 0 40px #F5403E, 0 0 60px #F5403E;
    }
  }

  i {
    margin-top: -3px;
    margin-left: 5px;    
  }

  @include desktop {
    width: 341px;
    height: 77px;

    font-size: 34px;
    line-height: 1.19;
    letter-spacing: 1.11px;

    background-image: url(../assets/buttons/button-desktop.png);
    background-image: 
      -webkit-image-set(
        url(../assets/buttons/button-desktop.png) 1x,
        url(../assets/buttons/button-desktop@2x.png) 2x,
      );
    background-image: 
      image-set(
        url(../assets/buttons/button-desktop.png) 1x,
        url(../assets/buttons/button-desktop@2x.png) 2x,
      );
  }

  @include widescreen {
    width: 332px;
    height: 75px;

    font-size: 34px;
    line-height: 1.19;
    letter-spacing: 1.08px;

    background-image: url(../assets/buttons/button-widescreen.png);
    background-image: 
      -webkit-image-set(
        url(../assets/buttons/button-widescreen.png) 1x,
        url(../assets/buttons/button-widescreen@2x.png) 2x,
      );
    background-image: 
      image-set(
        url(../assets/buttons/button-widescreen.png) 1x,
        url(../assets/buttons/button-widescreen@2x.png) 2x,
      );
  }

  @include widescreen-hd {
    width: 374px;
    height: 84px;

    font-size: 39px;
    line-height: 1.21;
    letter-spacing: 1.17px;

    background-image: url(../assets/buttons/button-widescreen-hd.png);
    background-image: 
      -webkit-image-set(
        url(../assets/buttons/button-widescreen-hd.png) 1x,
        url(../assets/buttons/button-widescreen-hd@2x.png) 2x,
      );
    background-image: 
      image-set(
        url(../assets/buttons/button-widescreen-hd.png) 1x,
        url(../assets/buttons/button-widescreen-hd@2x.png) 2x,
      );
  }

  @include fullhd {
    font-size: 39px;
    line-height: 1.2;
    letter-spacing: 1.23px;
  }

}

.separator {
  border-bottom: 2px solid #35465f;
  margin-bottom: 21.6px;
}

@import "header";
@import "section-forge";
@import "section-frontier";
@import "section-gallery";
@import "section-players";
@import "section-requirements";
@import "section-video";
@import "section-videos";
@import "section-war";