.section-gallery {
  margin-top: 85px;

  padding-left: 15px;
  padding-right: 15px;

  background-color: black;

  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -33px;
    width: 100%;
    height: 33px;

    background-position: center top;
    background-repeat: no-repeat;
    background-image: url(../assets/misc/separator-one.png);
    background-image:
      -webkit-image-set(
        url(../assets/misc/separator-one.png) 1x,
        url(../assets/misc/separator-one@2x.png) 2x,
      );
    background-image: 
      image-set(
        url(../assets/misc/separator-one.png) 1x,
        url(../assets/misc/separator-one@2x.png) 2x,
      );    
  }

  .columns {
    position: relative;

    .column {
      text-align: left;
  
      &:nth-child(odd) {
        text-align: right;
      }
  
      img {
        width: 100%;
      }
    }

    @include tablet {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -25px;
        bottom: 0;
        left: -25px;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      }
    }

    @include widescreen {
      &:after {
        right: -98px;
        left: -98px;
      }
    }
  
    @include widescreen-hd {
      &:after {
        right: -135px;
        left: -135px;
      }
    }
  
    @include fullhd {
      &:after {
        right: -160px;
        left: -160px;
      }
    }
  }

  @include desktop {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 165px;

    &:before {
      left: 0;
      right: 0;
      top: -185px;
      width: 100%;
      height: 351px;
      
      background-position: -33px top, calc(100% + 33px) top, center 150px;
      background-repeat: no-repeat;
      background-image: url(../assets/misc/hex-left.png), url(../assets/misc/hex-right.png), url(../assets/misc/separator-one.png);
      background-image:
        -webkit-image-set(
          url(../assets/misc/hex-left.png) 1x,
          url(../assets/misc/hex-left@2x.png) 2x,
        ),
        -webkit-image-set(
          url(../assets/misc/hex-right.png) 1x,
          url(../assets/misc/hex-right@2x.png) 2x,
        ),
        -webkit-image-set(
          url(../assets/misc/separator-one.png) 1x,
          url(../assets/misc/separator-one@2x.png) 2x,
        );
      background-image: 
        image-set(
          url(../assets/misc/hex-left.png) 1x,
          url(../assets/misc/hex-left@2x.png) 2x,
        ),
        image-set(
          url(../assets/misc/hex-right.png) 1x,
          url(../assets/misc/hex-right@2x.png) 2x,
        ),
        image-set(
          url(../assets/misc/separator-one.png) 1x,
          url(../assets/misc/separator-one@2x.png) 2x,
        );

      @include fullhd-xl {
        background-image:
        -webkit-image-set(
          url(../assets/misc/hex-left-hd.png) 1x,
          url(../assets/misc/hex-left-hd@2x.png) 2x,
        ),
        -webkit-image-set(
          url(../assets/misc/hex-right-hd.png) 1x,
          url(../assets/misc/hex-right-hd@2x.png) 2x,
        ),
        -webkit-image-set(
          url(../assets/misc/separator-one.png) 1x,
          url(../assets/misc/separator-one@2x.png) 2x,
        );
      background-image: 
        image-set(
          url(../assets/misc/hex-left-hd.png) 1x,
          url(../assets/misc/hex-left-hd@2x.png) 2x,
        ),
        image-set(
          url(../assets/misc/hex-right-hd.png) 1x,
          url(../assets/misc/hex-right-hd@2x.png) 2x,
        ),
        image-set(
          url(../assets/misc/separator-one.png) 1x,
          url(../assets/misc/separator-one@2x.png) 2x,
        );
      }
    }
  }

  @include widescreen {
    padding-left: 98px;
    padding-right: 98px;

    &:before {
      background-position: -3px top, calc(100% + 3px) top, center 150px;
    }
  }

  @include widescreen-hd {
    padding-left: 135px;
    padding-right: 135px;
  }

  @include fullhd {
    padding-left: 175px;
    padding-right: 175px;
  }
}
