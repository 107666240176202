.section-video {
  background-size: cover;
  background-position: center center;

  background-image: linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%), url(../assets/bg/bg-video-mobile.jpg);
  background-image:
    -webkit-linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
    -webkit-image-set(
      url(../assets/bg/bg-video-mobile.jpg) 1x,
      url(../assets/bg/bg-video-mobile@2x.jpg) 2x,
    );
  background-image: 
    linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
    image-set(
      url(../assets/bg/bg-video-mobile.jpg) 1x,
      url(../assets/bg/bg-video-mobile@2x.jpg) 2x,
    );

  video {
    width: 100%;
  }

  @include desktop {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%), url(../assets/bg/bg-video-desktop.jpg);
    background-image:
      -webkit-linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
      -webkit-image-set(
        url(../assets/bg/bg-video-desktop.jpg) 1x,
        url(../assets/bg/bg-video-desktop@2x.jpg) 2x,
      );
    background-image: 
      linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
      image-set(
        url(../assets/bg/bg-video-desktop.jpg) 1x,
        url(../assets/bg/bg-video-desktop@2x.jpg) 2x,
      );
  }

  @include widescreen {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%), url(../assets/bg/bg-video-widescreen.jpg);
    background-image:
      -webkit-linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
      -webkit-image-set(
        url(../assets/bg/bg-video-widescreen.jpg) 1x,
        url(../assets/bg/bg-video-widescreen@2x.jpg) 2x,
      );
    background-image: 
      linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
      image-set(
        url(../assets/bg/bg-video-widescreen.jpg) 1x,
        url(../assets/bg/bg-video-widescreen@2x.jpg) 2x,
      );
  }

  @include fullhd {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%), url(../assets/bg/bg-video-fullhd.jpg);
    background-image:
      -webkit-linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
      -webkit-image-set(
        url(../assets/bg/bg-video-fullhd.jpg) 1x,
        url(../assets/bg/bg-video-fullhd@2x.jpg) 2x,
      );
    background-image: 
      linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
      image-set(
        url(../assets/bg/bg-video-fullhd.jpg) 1x,
        url(../assets/bg/bg-video-fullhd@2x.jpg) 2x,
      );
  }

  @include fullhd-xl {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%), url(../assets/bg/bg-video-fullhd-xl.jpg);
    background-image:
      -webkit-linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
      -webkit-image-set(
        url(../assets/bg/bg-video-fullhd-xl.jpg) 1x,
        url(../assets/bg/bg-video-fullhd-xl@2x.jpg) 2x,
      );
    background-image: 
      linear-gradient(0deg, rgba(0,0,0,0.67) 0%, rgba(0,0,0,0.67) 100%),
      image-set(
        url(../assets/bg/bg-video-fullhd-xl.jpg) 1x,
        url(../assets/bg/bg-video-fullhd-xl@2x.jpg) 2x,
      );
  }

  .video-wrapper {
    position: relative;
  }

  video[poster]{
    object-fit: cover;
  }

  .play-overlay {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: pointer;

    background-size: calc(100vw / 11);
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../assets/buttons/button-play.png);

    transition: background-size 0.4s;
    -moz-transition: background-size 0.4s;
    -ms-transition: background-size 0.4s;
    -o-transition: background-size 0.4s;
    -webkit-transition: background-size 0.4s;

    transition: background-image 0.4s linear;
    -moz-transition: background-image 0.4s linear;
    -ms-transition: background-image 0.4s linear;
    -o-transition: background-image 0.4s linear;
    -webkit-transition: background-image 0.4s linear;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-size: calc(100vw / 11);
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(../assets/buttons/button-play-bg.png);

      transition: background-image 0.4s linear;
      -moz-transition: background-image 0.4s linear;
      -ms-transition: background-image 0.4s linear;
      -o-transition: background-image 0.4s linear;
      -webkit-transition: background-image 0.4s linear;
    }

    &:hover {
      background-image: url(../assets/buttons/button-play-on.png);
      background-size: calc(100vw / 10.8);

      &:before {
        background-image: url(../assets/buttons/button-play-bg-on.png);
      }
    }
  }

  padding: 19px 10px 15px 10px;

  .video-wrapper {
    margin-bottom: 28px;
  }

  @include desktop {
    margin-left: 25px;
    margin-right: 25px;

    padding: 18px 18px 20px 18px;

    background-color: rgba(0, 0, 0, 0.67);

    .subtitle {
      font-size: 20px;
      line-height: 1.5;
      letter-spacing: 0.2px;

      margin-left: 163px;
      margin-right: 163px;      
    }

    .video-wrapper {
      margin-bottom: 42.5px;
    }
  }

  @include widescreen {
    margin-left: 83px;
    margin-right: 83px;

    padding: 23px 23px 45px 23px;

    .subtitle {
      font-size: 24px;
      line-height: 1.42;
      letter-spacing: 0.24px;      
    }
  }

  @include widescreen-hd {
    margin-left: 120px;
    margin-right: 120px;

    padding: 24px 24px 45px 24px;

    .subtitle {
      font-size: 24px;
      line-height: 1.42;
      letter-spacing: 0.24px;
    }
  }

  @include fullhd {
    margin-left: 160px;
    margin-right: 160px;

    padding: 26px 26px 50px 26px;
  }

  @include fullhd-xl {
    margin-left: 200px;
    margin-right: 200px;

    padding: 18px 28px 39px 28px;

    .subtitle {
      font-size: 27px;
      line-height: 1.26;
      letter-spacing: 0.27px;
    }
  }
}